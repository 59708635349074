<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-grid">
        <span class="lato-black" style="font-size: 30px;">Settings</span>
      </v-col>
      <v-col md="12" lg="3">
        <v-navigation-drawer width="100%" permanent>
          <v-list nav class="pl-0">
            <v-list-item-group v-model="item" color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                @click="$router.push('/general/' + item.path)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="lato-bold font-16">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col md="12" lg="9">
        <router-view></router-view>
        <!--        <my-licences v-if="item === 1"></my-licences>-->
        <!--        <personal-information v-if="item === 2"></personal-information>-->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  created () {
    const currentRoute = this.$route.path
    const foundedPart = currentRoute.split('/')[2]

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].path === foundedPart) {
        this.item = i
      }
    }
  },
  name: 'GeneralSettings',
  data: () => ({
    item: 0,
    items: [
      {
        text: 'Token Settings',
        icon: 'mdi-shield-key-outline',
        path: 'token-settings'
      },
      {
        text: 'Parameter Settings',
        icon: 'mdi-tune',
        path: 'parameter-settings'
      }
    ]
  })
}
</script>

<style scoped>

</style>
